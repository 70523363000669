import "./hero.scss";

import { Link } from "gatsby";
import * as React from "react";
import { PropsWithChildren, useState } from "react";

import ModalForm from "../../../components/ModalForm";
import { CitaOnline } from "../../../components/ModalForm/ContactForm/CitaOnline";

interface PropsHero extends PropsWithChildren<any> {
  title: string;
  position?: string;
  description: string[];
  cta_text?: string;
  is_home: boolean;
}

const Hero: React.FC<PropsHero> = (props: PropsHero) => {
  const [has_modal, setHasModal] = useState(false);
  return (
    <>
      {has_modal && (
        <ModalForm
          title="Consulta online"
          form={CitaOnline}
          is_active={setHasModal}
        />
      )}
      <section className={"o-hero container"}>
        <div className={"m-hero-content"}>
          <h1 className={"a-line-right"}>{props.title}</h1>
          {props.position && <p className={"a-position"}>{props.position}</p>}
          {props.description.map((text, index) => (
            <p key={index} className={"a-desc"}>
              {text}
            </p>
          ))}

          <div className={"is-inline-block"}>
            {props.cta_text && (
              <button
                className="a-button is-block"
                onClick={() => {
                  setHasModal(true);
                }}
              >
                {props.cta_text}
              </button>
            )}
            {props.is_home && (
              <>
                <Link
                  to={"/sobre-mi/"}
                  className={"a-link a-link--underline is-inline-block"}
                >
                  Conóceme mejor
                </Link>
              </>
            )}
          </div>
        </div>
        <div className={"m-hero-image"}>{props.children}</div>
      </section>
    </>
  );
};

export default Hero;
